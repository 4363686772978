.divFooter {
	height: 5vh;
	min-height: 5vh;
	padding: 1vh 3vh;
	background-color: #00B4D8;
}

.divFooter > h6 {
	margin-left: 8px;
	font-family: Nunito;
	font-size: 14px;
	color: aliceblue;
}

.terminos {
	width: 100%;
	color: var(--text-color-claro);
	text-decoration: none;
	transition: 0.3s;
}

.terminosFoo {
	width: 100%;
	color: aliceblue;
	text-decoration: none;
	transition: 0.3s;
}
.terminos:hover {
	color: var(--text-color-claro);
	font-weight: bold;
}

@media (max-width: 750px) {
	.divFooter {
		height: 5vh;
		min-height: 5vh;
		padding: 2px;
		background-color: #00B4D8;
	}
	.divFooter > h6 {
		/* margin-left: 8px; */
		font-family: Nunito;
		font-size: 12px;
		color: aliceblue;
	}
}
