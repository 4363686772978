.fixedGlobo {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2;
	margin-left: 6vw;
	margin-bottom: 15vh;
	background-color: var(--color-oscuro);
	align-items: center;
}

.derechaButton {
	position: fixed;
	bottom: 0;
	right: 0 !important;
	z-index: 2;
	margin-right: 5vw;
	margin-bottom: 15vh;
	background-color: var(--color-oscuro);
	align-items: center;
}

.grupoGlobo {
	width: 35px !important;
}
.grupoGlobo > button {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: center;
	background-color: var(--color-oscuro) !important;
	border: 5px solid #fff !important;
	border-radius: 50%;
	box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
	-webkit-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
	-moz-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
}
.grupoGlobo > button:active {
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
}
.imgBotonGlobo > img {
	width: 100px;
}

.botonGlobo {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: center;
	background-color: var(--color-oscuro) !important;
	border: 1px solid #fff;
	width: 30px;
	height: 30px;
	font-size: 14px !important;
	transition: all 0.2s ease-in-out;
}

.grupoGlobo > button:hover {

	box-shadow: 25px 25px 2px 0px rgba(0, 0, 0, 0.48) !important;
	-webkit-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.48) !important;
	-moz-box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.48) !important;
	background-color: var(--light-blue)!important;
}
.grupoGlobo > button {
	transition: transform 0.2s ease;
}
.grupoGlobo > button:active {
	transform: scale(0.9);
	background-color: var(--color-oscuro) !important;
}
.botonGlobo:active {
	outline: none !important;
	/* box-shadow: none !important; */
	border-color: none !important;
	background-color: var(--color-oscuro) !important;
}
.botonGlobo:focus {
	outline: none !important;
	box-shadow: none !important;
	border-color: none !important;
	background-color: var(--color-oscuro) !important;
}

.botonGlobo > img {
	width: 30px;
	height: 30px;
}
.btn-check:active {
	/* box-shadow: none !important; */
	background-color: var(--color-oscuro) !important;
	border: none !important;
}
@media (max-width: 500px) {
	.fixedGlobo {
		transform: scale(0.7);
		margin-bottom: 9vh;
		margin-left:9vw
	}

	.dropdown-menu {
		/* transform: scale(1.1) !important; */
		padding-right: 30px !important;
	
	}

}
/* @keyframes swing {
	15% {
		transform: translateX(5px);
	}
	30% {
		transform: translateX(-5px);
	}
	50% {
		transform: translateX(3px);
	}
	65% {
		transform: translateX(-3px);
	}
	80% {
		transform: translateX(2px);
	}
	100% {
		transform: translateX(0);
	}
} */

.respira {
	transition: transform 1s ease-in-out;
	animation: breathingAnimation 2s ease-in-out infinite;
}
@keyframes breathingAnimation {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.botonGlobo > p {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	margin: 10px 0 0 4px;
}

.dropdown-menu {
	min-width: 60px !important;
	margin-left: 35px;
	margin-bottom: 5px;
	background-color: #fff!important;

}
.dropdown-menu:hover {
	box-shadow: 5px 5px 2px 0px rgba(0, 0, 0, 0.23) !important;
}

.dropdown-menu > a {
	margin: 0 !important;
	margin-left: -1.4vw !important;
}

.linkGlobo {
	text-decoration: none;
	color: var(--text-color-bar);
	max-width: 10px !important;
}

.linkGlobo > i {
	font-size: 1.8rem;
	padding: 8px;
	transition: all 0.5s;
	margin-left: -0.6vw;
}
.linkGlobo > i:hover {
	text-shadow: 1px 1px rgb(102, 102, 102);
}

.dropdown-item:focus {
	outline: none !important;
	box-shadow: none !important;
	border-color: none !important;
	background-color: transparent !important;
}
.dropdown-item:active {
	outline: none !important;
	box-shadow: none !important;
	border-color: none !important;
	background-color: transparent !important;
}
.dropdown-item:hover {
	outline: none !important;
	box-shadow: none !important;
	border-color: none !important;
	background-color: transparent;
}
