.containerPromotion {
	/* height: 50vh; */
	width: 100%;
	background-repeat: no-repeat;
	object-fit: cover;
	position: relative;
}

.fondoPreguntas {
  position: relative;
  overflow: hidden;
}
.fondoPreguntas::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #EFFCFF, #0077B6, rgba(255, 255, 255, 0));
  pointer-events: none; /* Ensure the pseudo-element doesn't interfere with interaction */
  z-index: 0; /* Make sure the pseudo-element is behind other content */
}
.MuiPaper-root{
	box-shadow: none!important;
}

.shadoww{
	 box-shadow:rgba(0, 0, 0, 0.25) !important
}
.containerPromotion > img {
	--mask: radial-gradient(78.1px at 50% 110px, #000 99%, #0000 101%) calc(50% - 100px) 0/200px 100%,
		radial-gradient(78.1px at 50% -60px, #0000 99%, #000 101%) 50% 50px/200px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
	height: 70vh;
	width: 100%;
	background-repeat: no-repeat;
	object-fit: cover;
}

.edit-icon-promotion {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	position: absolute;
	bottom: 5%;
	right: 5%;
	background-color: var(--color-verde-claro);

	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-promotion:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
