header {
	width: 100%;
	height: 17vh;
}

.mainLogin {
	margin-top: 17vh;
	padding-top: 5vh;
	height: 83vh;
	text-align: center;
	overflow-y: hidden;

	background-color: #ffffff;
}

main > h2 {
	margin-top: 8vh;
	font-size: 2rem;
}
.superadminButtons {
	display: flex;
}
main > h4 {
	font-size: 1rem;
}
.fondoLogin {
	background-color: var(--light-blue);
	height: 100vh;
}

.containerForm {
	max-width: 50vw;
	margin-top: 5vh;
}
.containerForm > h4 {
	color: var(--text-color-claro);
	font-size: 1.2rem;
}

/* .contraseña > h4 {
	font-size: 1.5rem;
} */

.link {
	color: var(--text-color-claro);
	font-size: 1rem;
	text-decoration: none;
	font-weight: 500;
}
.link:hover {
	text-decoration: underline;
	color: var(--text-color-claro);
	cursor: pointer;
	font-weight: bold;
}
.botonIngresar > button {
	width: 80%;
	background-color: var(--primary-blue);
	border-radius: 15px;
	font-weight: bold;
	font-size: 1rem;
	letter-spacing: 2px;
}
.superadminButtons {
	display: flex;
}
.fondoLoginButton {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 50px;
	position: absolute;
	top: 18vh;
	left: 5%;
	background-color: var(--primary-blue);
	color: #ffffff;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	z-index: 1;
}

.toHome {
	margin-top: 5vh;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 50px;
	position: absolute;
	top: 18vh;
	left: 15%;
	background-color: var(--primary-blue);
	color: #ffffff;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	z-index: 1;
	border: none;
}

.toHome:hover {
	background-color: var(--light-blue);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.fondoLoginButton:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

/* .bi-eye-slash,
.bi-eye {
	font-size: 1.2rem;
} */
/* .input-group-text {
	padding-top: 13px !important;
	padding-bottom: 13px !important;
} */

.input-group-text > abbr {
	cursor: pointer;
}

.fondoToast {
	box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 5px 4px 5px 0px rgba(0, 0, 0, 0.2);
}

#signInDiv {
	margin-top: 5vh;
	display: flex;
	justify-content: center;
}

@media (max-width: 550px) {
	.containerForm {
		max-width: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.botonIngresar > button {
		width: 100%;
		font-size: 0.8rem;
	}
}

@media (max-width: 1100px) {
	.containerForm {
		max-width: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}
