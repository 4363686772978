header {
	top: 0;
	width: 80vw;
	height: 8vh;
	min-height: 8vh;
	position: fixed;
	z-index: 2;
}

.mainHome {
	/* margin-top: 6vh; */
	min-height: 100vh;
	text-align: center;
	overflow-y: hidden;
	overflow-x: hidden;
	background-color: white;
	padding-bottom: 10vh;
}
/* .fondoHome {
	background-image: url('../../assets/fondos/FONDO1.png');
} */

/* En tu archivo CSS */
.blur-top-edge {
  position: relative;
  overflow: hidden;
}

.blur-top-edge::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 300px; /* Controla la altura del área difuminada */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  pointer-events: none; /* Evita la interacción con el mouse */
}

.sectionCarousel {
	background-color: var(--color-claro);

}

.sectionAbout {
	background-color: var(--color-gris-trans);
	--mask: radial-gradient(56.6px at 50% calc(100% - 78px), #000 99%, #0000 101%) calc(50% - 60px) 0/120px
			100%,
		radial-gradient(56.6px at 50% calc(100% + 48px), #0000 99%, #000 101%) 50% calc(100% - 30px) /
			120px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}

.sectionAbout2 {
	background-color: var(--color-gris-trans);
	--mask: radial-gradient(56.6px at 50% 78px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
		radial-gradient(56.6px at 50% -48px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}

.sectionVideo {
	margin-top: 5vh;
	margin-bottom: 5vh;
	display: flex;
	justify-content: center;
	padding: 20px;
	background-color: var(--color-claro);
	border: solid 10px var(--color-verde);
}

.divVideo {
	width: 40%;
	height: 90%;
}

.textContainer {
	width: 40%;

	display: flex;
	background-color: var(--color-claro);
	text-align: center;
	align-items: center;
	justify-content: center;
}
.mark {
	background-color: var(--color-claro);
	width: 60%;
}

.cotizacion {
	text-align: center;
	align-items: center;
	margin-top: 5vh;
	margin-bottom: 3vh;
	color: var(--color-claro);
	background-color: var(--color-verde);
	width: 20vw;

	border: none;
	height: 6vh;
}

.fondohome {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 50px;
	position: absolute;
	bottom: 0;
	left: 5%;
	background-color: var(--color-verde);
	color: #ffffff;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	z-index: 1;
}
.edit-icon-video {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	margin: 2px 2px;
	background-color: var(--color-verde);
	color: var(--text-color);
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	/* z-index: 1; */
}

.edit-icon-video:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.sectionScroll {
	width: 100vw;
	height: 15vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column-reverse;
}
.sectionScroll > img {
	width: 40px;
	animation: scrollAnimation 2s infinite; /* 2s representa la duración de la animación, puedes ajustarlo según tus preferencias */
	transition-timing-function: linear;
}
@keyframes scrollAnimation {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px); /* Ajusta la distancia que deseas para el movimiento vertical */
	}
	100% {
		transform: translateY(0);
	}
}

.fondohome:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
@media (max-width: 750px) {
	.sectionCarousel {
		background-color: var(--color-claro);
		margin-top: -15%;
	}
	.cotizacion {
		margin-top: 7vh;
		width: 90vw;
	}
}
@media (max-width: 450px) {
	.sectionVideo {
		flex-direction: column;
	}
	.divVideo {
		width: 100%;
	}
	.textContainer {
		width: 100%;
	}
	.cotizacion {
		width: 90vw;
	}
	.mainHome {
		margin-top: 0vh;
	}
	.sectionCarousel {
		top: -9vh;
		width: 100%;
		height: 33vh;
	}
	.fondohome {
		top: 20vh;
	}
}
