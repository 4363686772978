.mainAbout {
	/* background-image: url('https://res.cloudinary.com/duomlqsl4/image/upload/v1718553950/WhatsApp_Image_2024-06-16_at_13.04.32_ivglcs.jpg'); */
	height: 95vh;
	background-size: cover;
	background-position: center;
	/* background-size: cover; */
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
}

.containerAbout {
	background-color: rgba(240, 248, 255, 0.788);
	/* margin-top: vh; */
	display: flex;
	flex-direction: column; /* Cambiamos la dirección a columna */
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	padding: 20px;
	width: 60%; /* Ancho del 100% para llenar el contenedor */
}

.aboutText {
	font-size: 1.5rem;
	color: #333; /* Opcional: color de texto */
}

@media (max-width: 750px) {
	.mainAbout {
		background-size: auto;
		background-position: center;
	}
	.containerAbout {
		border-radius: 10px;
		width: 90vw;
		height: auto;
		margin-top: 20vh;
	}
	.containerMark {
		background: rgba(255, 255, 255, 0.685); /* Fondo con opacidad */
		margin-top: 0vh;

		z-index: 0;
	}
	.aboutText {
		font-size: 1rem;
		color: #333; /* Opcional: color de texto */
	}
}
